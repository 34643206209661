import { createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import { set_token_error } from './loginSlice';
import { add_tab, update_tab } from './hotelSlice';
import { getFileFromUrl } from '../../api/functions';

export const shopSlice = createSlice({
    name: 'shop',

    initialState: {
        shop: null,
        shop_fetching: false,
        edit_shop_fetching: false,
    },

    reducers: {
        set_shop: (state, action) => {
            state.shop = action.payload;
        },
        set_shop_fetching: (state, action) => {
            state.shop_fetching = action.payload;
        },
        set_shop_items: (state, action) => {
            state.shop = {
                ...state.shop,
                subitems: state.shop.subitems.map(cat => {
                    if (cat.id === action.payload.shop_id) {
                        return {
                            ...cat,
                            items: action.payload.items,
                        };
                    }

                    return cat;
                }),
            };
        },
        add_shop_item: (state, action) => {
            state.shop.subitems.find(cat => cat.id === action.payload.shop_id).items.unshift(action.payload);
        },
        update_shop_item: (state, action) => {
            state.shop = {
                ...state.shop,
                subitems: state.shop.subitems.map(cat => {
                    if (cat.id === action.payload.shop_id) {
                        return {
                            ...cat,
                            items: cat.items.map(item => {
                                if (item.id === action.payload.id) {
                                    return { ...item, ...action.payload };
                                }

                                return item;
                            }),
                        };
                    }

                    return cat;
                }),
            };
        },
        add_shop_category: (state, action) => {
            state.shop.subitems.push(action.payload);
        },
        update_shop_category: (state, action) => {
            state.shop = {
                ...state.shop,
                subitems: state.shop.subitems.map(cat => {
                    if (cat.id === action.payload.id) {
                        return {
                            ...cat,
                            ...action.payload,
                        };
                    }

                    return cat;
                }),
            };
        },
    },
});

export const { set_shop_fetching, set_shop, add_shop_item, update_shop_item, set_shop_items, add_shop_category, update_shop_category } = shopSlice.actions;

export const getShop =
    ({ hotel_id }) =>
    async (dispatch, getState) => {
        dispatch(set_shop_fetching(true));

        try {
            const state = getState();
            if (hotel_id) {
                const response = await api.getHotelList.fetch(state.login.access_token);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.length) {
                        const hotel = res.find(hotel => hotel.id === hotel_id);
                        if (hotel) {
                            dispatch(
                                set_shop({
                                    ...hotel.subitems.find(tab => tab.link === 'shop_2'),
                                    hotel_name: hotel.Name,
                                })
                            );
                        }
                    } else {
                        dispatch(set_shop(null));
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('getShop error: ', e);
        } finally {
            dispatch(set_shop_fetching(false));
        }
    };

export const addShopItem =
    ({ data }) =>
    async (dispatch, getState) => {
        try {
            const state = getState();

            if (data.hotel_id) {
                const files = await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`)));
                const response = await api.addShopItem.fetch(state.login.access_token, { ...data, files });
                if (response.status === 200) {
                    const res = await response.json();

                    if (!res.error && res.id) {
                        const files = res.files.map((file, id) => ({ url: file, id: id + 1 }));

                        dispatch(
                            add_shop_item({
                                ...data,
                                id: res.id,
                                files2: files,
                            })
                        );

                        return { status: 1 };
                    } else {
                        return {
                            status: 0,
                            error: res?.error || 'Что-то пошло не так. Обновите страницу и попробуйте еще раз',
                        };
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                    return { status: 0 };
                }
            } else {
                return { status: 0, error: 'Что-то пошло не так. Обновите страницу и попробуйте еще раз' };
            }
        } catch (e) {
            console.log('addShopItem error: ', e);
            return { status: 0, error: e };
        }

        return { status: 0 };
    };

export const editShopItem =
    ({ data }) =>
    async (dispatch, getState) => {
        try {
            const state = getState();

            if (data.id) {
                const files = data.hasOwnProperty('files')
                    ? await Promise.all(data.files.map(async file => await getFileFromUrl(file.url, `${Date.now()}.jpg`)))
                    : [];

                const response = await api.editShopItem.fetch(state.login.access_token, { ...data, files });

                if (response.status === 200) {
                    const res = await response.json();

                    if (!res.error) {
                        if (res.files) {
                            const files = res.files.map((file, id) => ({ url: file, id: id + 1 }));

                            dispatch(update_shop_item({ ...data, files2: files || [], files: res.files }));
                        } else {
                            dispatch(update_shop_item(data));
                        }

                        return { status: 1 };
                    } else {
                        return { status: 0, error: res.error };
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                    return { status: 0 };
                }
            } else {
                return { status: 0, error: 'Что-то пошло не так. Обновите страницу и попробуйте еще раз' };
            }
        } catch (e) {
            console.log('editShopItem error: ', e);
            return { status: 0, error: e };
        }

        return { status: 0 };
    };

export const sortShopItem =
    ({ data }) =>
    async (dispatch, getState) => {
        dispatch(set_shop_items(data));

        try {
            const state = getState();
            if (data && data.items) {
                const sort_arr = data.items.map(el => el.id);
                const response = await api.sortShopItems.fetch(state.login.access_token, { ids_items: JSON.stringify(sort_arr), shop_id: data.shop_id });
                if (response.status === 200) {
                    await response.json();
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                }
            }
        } catch (e) {
            console.log('sortShopItem error: ', e);
        }
    };

export const addShopCategory =
    ({ data }) =>
    async (dispatch, getState) => {
        try {
            const state = getState();

            if (data.hotel_id) {
                const response = await api.addShopCategory.fetch(state.login.access_token, data);

                if (response.status === 200) {
                    const res = await response.json();

                    if (!res.error && res.id) {
                        dispatch(
                            add_shop_category({
                                ...data,
                                active: '1',
                                id: res.id,
                                items: [],
                            })
                        );
                        dispatch(
                            add_tab({
                                ...data,
                                active: '1',
                                id: res.id,
                                items: [],
                            })
                        );

                        return { status: 1, id: res.id };
                    } else {
                        return { status: 0, error: res.error };
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                    return { status: 0 };
                }
            } else {
                return { status: 0, error: 'Что-то пошло не так. Обновите страницу и попробуйте еще раз' };
            }
        } catch (e) {
            console.log('editShopCategory error: ', e);
            return { status: 0, error: e };
        }

        return { status: 0 };
    };

export const editShopCategory =
    ({ data }) =>
    async (dispatch, getState) => {
        try {
            const state = getState();

            if (data.id) {
                const response = await api.editShopCategory.fetch(state.login.access_token, data);

                if (response.status === 200) {
                    const res = await response.json();

                    if (!res.error) {
                        dispatch(update_shop_category(data));
                        dispatch(update_tab(data));

                        return { status: 1 };
                    } else {
                        return { status: 0, error: res.error };
                    }
                } else if (response.status === 401) {
                    dispatch(set_token_error(true));
                    return { status: 0 };
                }
            } else {
                return { status: 0, error: 'Что-то пошло не так. Обновите страницу и попробуйте еще раз' };
            }
        } catch (e) {
            console.log('editShopCategory error: ', e);
            return { status: 0, error: e };
        }

        return { status: 0 };
    };

export const shopState = state => state.shop.shop;
export const shopFetchingState = state => state.shop.shop_fetching;

export default shopSlice.reducer;
