import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BallTriangle } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { listenForOutsideClick } from '../../api/functions';
import { editFood, food as food_state, food_fetching as food_fetching_state, getFood, sortFood } from '../../redux/slices/foodSlice';
import { access_token as access_token_state } from '../../redux/slices/loginSlice';
import { ContentWrapper } from '../common/ContentWrapper';
import { SortableWrapper } from '../common/sortable/SortableWrapper';
import { SortableItem } from '../common/sortable/SortableItem';
import NoFood from '../../source/images/no-food.png';

export const List = ({ tab, setDetailId }) => {
    const dispatch = useDispatch();
    const editBlockRef = useRef(null);
    const access_token = useSelector(access_token_state);
    const food = useSelector(food_state);
    const food_fetching = useSelector(food_fetching_state);

    const [editBlockIsOpen, setEditBlockIsOpen] = useState(null);
    const [listening, setListening] = useState(false);
    const [categoryId, setCategoryId] = useState(null);
    const [category, setCategory] = useState(null);
    const [fetchData, setFetchData] = useState({ type: '', data: null });
    const [fetchAccessToken, setFetchAccessToken] = useState('');

    const { t, i18n } = useTranslation();

    useEffect(listenForOutsideClick(listening, setListening, editBlockRef, setEditBlockIsOpen));

    useEffect(() => {
        if (tab && access_token) {
            dispatch(getFood({ access_token, hotel_id: tab.hotel_id }));
            setCategoryId(null);
        }
    }, [tab]);

    useEffect(() => {
        if (food && food.length && !categoryId) {
            setCategoryId(food[0].id);
        }
    }, [food]);

    useEffect(() => {
        if (food && food.length && categoryId) {
            setCategory(food.find(el => el.id === categoryId));
        }
    }, [categoryId, food]);

    const editStopListHandler = async data => {
        let res;
        if (access_token) {
            setFetchAccessToken(access_token);
            setFetchData({ type: 'edit', data });
            res = await dispatch(editFood({ access_token, data }));
        }

        if (res.status) {
            setEditBlockIsOpen(false);
        }
    };

    const sortDispatch = data => {
        if (access_token) {
            const sortedFood = food.map(cat => {
                if (cat.id === category.id) {
                    return { ...cat, items: data };
                }
                return cat;
            });
            setCategory({ ...category, items: data });

            setFetchAccessToken(access_token);
            setFetchData({ type: 'sort', data });

            dispatch(sortFood({ access_token, sortedFood: sortedFood, sortedData: data }));
        }
    };

    useEffect(() => {
        (async () => {
            if (fetchAccessToken && fetchAccessToken !== access_token) {
                if (fetchData.type === 'sort') {
                    sortDispatch(fetchData.data);
                } else if (fetchData.type === 'edit') {
                    await editStopListHandler(fetchData.data);
                }
            }
        })();
    }, [access_token]);

    if (food_fetching) {
        return (
            <div className="mt-8 flex w-full justify-center">
                <BallTriangle height="50" width="100" color="#3b82f6" ariaLabel="loading" />
            </div>
        );
    }

    if (category && category.hasOwnProperty('id')) {
        return (
            <ContentWrapper>
                <div className="-mx-4 -mt-4 mb-4 flex h-16 flex-row overflow-auto bg-white sm:-mx-6 sm:-mt-6 sm:mb-6">
                    {food.length &&
                        food.map(cat => (
                            <button
                                className={`whitespace-nowrap border-2 border-white px-6 ${categoryId === cat.id ? 'border-b-blue-500' : ''}`}
                                key={cat.name_en}
                                onClick={() => setCategoryId(cat.id)}
                            >
                                {i18n.language === 'ru' ? cat.name : cat.name_en}
                            </button>
                        ))}
                </div>
                <div className="flex flex-wrap gap-6">
                    {category.hasOwnProperty('items') && category.items.length ? (
                        <SortableWrapper items={category.items} sortDispatch={sortDispatch}>
                            {category.items.map(item => (
                                <SortableItem key={`${item.name_en || item.name}${item.id}`} id={item.id}>
                                    <div className="relative flex h-72 w-64 flex-col overflow-hidden rounded-2 bg-white drop-shadow">
                                        {item.active === '0' && (
                                            <div className="absolute flex h-full w-full items-center justify-center bg-white bg-opacity-50">
                                                <div className="w-2/3 bg-green-500 py-2 text-center text-sm text-white">{t('SHOP_IN_STOP_LIST')}</div>
                                            </div>
                                        )}
                                        <div className="absolute right-0 top-0" ref={editBlockIsOpen === item.id ? editBlockRef : null}>
                                            <button
                                                className="flex h-11 w-11 items-center justify-center rounded-bl-2 bg-gray-600 text-white transition-colors hover:bg-gray-800"
                                                onClick={() => (editBlockIsOpen === item.id ? setEditBlockIsOpen(null) : setEditBlockIsOpen(item.id))}
                                            >
                                                <svg className="h-6 w-6 fill-current stroke-current" viewBox="0 0 24 24">
                                                    <path
                                                        d="M17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12Z"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12Z"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>

                                            <div
                                                className={`absolute right-0 overflow-hidden rounded-lg bg-white drop-shadow-2xl transition-opacity  ${
                                                    editBlockIsOpen === item.id ? 'showed opacity-100' : 'hidden opacity-0'
                                                }`}
                                            >
                                                <button
                                                    className="flex w-full cursor-pointer flex-row items-center whitespace-nowrap border-0 p-3 text-left text-sm font-normal text-black hover:bg-gray-50"
                                                    type="button"
                                                    onClick={async () => {
                                                        await editStopListHandler({
                                                            ...item,
                                                            active: item.active === '0' ? '1' : '0',
                                                        });
                                                    }}
                                                >
                                                    <svg className="mr-2 h-4 w-4 fill-transparent stroke-current" viewBox="0 0 16 16">
                                                        <path
                                                            d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                                                            strokeOpacity="0.85"
                                                            strokeWidth="1.33333"
                                                            strokeMiterlimit="10"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M3.83325 3.8335L12.1666 12.1668"
                                                            stroke="black"
                                                            strokeOpacity="0.85"
                                                            strokeWidth="1.33333"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    {t('SHOP_STOP_LIST')}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mb-3 h-36 w-full shrink-0">
                                            {item.img_path ? (
                                                <img className="h-full w-full object-cover" src={item.files[0]} alt={item.name} />
                                            ) : (
                                                <img src={NoFood} alt="no-food" className="h-full w-full object-contain p-4" />
                                            )}
                                        </div>
                                        <div className="z-10 flex h-full w-full flex-col px-3 pb-3 text-base">
                                            <div className="mb-3 line-clamp-2 w-full font-semibold text-gray-800">
                                                {i18n.language === 'ru' ? item.name : item.name_en}
                                            </div>
                                            <div className="w-full font-normal text-gray-500">
                                                {item.price}₽ {item.weight ? `${item.weight}${category.mass}` : ''}
                                            </div>

                                            <button
                                                className="mt-auto h-8 w-full rounded border border-blue-500 bg-white text-sm text-blue-500 transition-colors hover:bg-blue-500 hover:text-white"
                                                onClick={() => setDetailId(item.id)}
                                            >
                                                {t('EDIT')}
                                            </button>
                                        </div>
                                    </div>
                                </SortableItem>
                            ))}
                        </SortableWrapper>
                    ) : (
                        <div>{t('SHOP_NO_ITEMS')}.</div>
                    )}
                </div>
            </ContentWrapper>
        );
    }
};
