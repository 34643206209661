import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { InitialValues } from './utils/types';
import { useTranslation } from 'react-i18next';

type Props = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    formData: InitialValues;
    compleatActionRef: MutableRefObject<boolean>;
};
export const ActionConfirmPopup: FC<Props> = ({ setIsOpen, formData, compleatActionRef }) => {
    const { t } = useTranslation();

    const [action, setAction] = useState<{ key: string; value: string | boolean } | null>(null);

    useEffect(() => {
        for (const key in formData) {
            if (key === 'allDeposit' || key === 'depositSum' || key === 'refundDeposit' || key === 'refundPayment') {
                if (formData[key] !== false && formData[key] !== '') {
                    setAction({ key, value: formData[key] });
                }
            }
        }
    }, [formData]);

    const getActionText = () => {
        switch (action?.key) {
            case 'allDeposit':
                return t('CHAT_DEPOSIT_24');
            case 'depositSum':
                return `${t('CHAT_DEPOSIT_25')} ${action.value} ${t('CHAT_DEPOSIT_26')}`;
            case 'refundDeposit':
                return t('CHAT_DEPOSIT_27');
            case 'refundPayment':
                return t('CHAT_DEPOSIT_28');
            default:
                break;
        }
    };

    const handlerPopupClose = (status: boolean) => {
        if (status) compleatActionRef.current = true;
        setIsOpen(false);
    };
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-slate-900 bg-opacity-30 opacity-100 transition-opacity duration-200">
            <div className="z-60 rounded-lg bg-white p-4 shadow-2xl transition-all duration-200 ease-in-out">
                <div className="text-xl">{t('CHAT_DEPOSIT_23')}</div>
                <div className="mt-3 text-center text-sm">{getActionText()}</div>
                <div className=" mt-3 flex text-sm">
                    <div className="ml-auto mr-auto">
                        <button type="button" onClick={() => handlerPopupClose(true)} className="mr-4 transition-colors hover:text-blue-500">
                            {t('YES')}
                        </button>
                        <button type="button" onClick={() => handlerPopupClose(false)} className="transition-colors hover:text-blue-500">
                            {t('NO')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
