import { useRef, useState } from 'react';
import { ChatMenu } from './ChatMenu';
import { useTranslation } from 'react-i18next';

import { DepositPopup } from './DepositPopup';
export const ListItem = ({ chatItem, changeHotel, handlerGetMessages, bookingId, hotelId }) => {
    const buttonRef = useRef(null);

    const [isOpenChatMenu, setIsOpenChatMenu] = useState(false);
    const [isOpenPopup, setIsOpenPopup] = useState(false);

    const { t } = useTranslation();

    const getDepositStatus = () => {
        if (chatItem.depositStatus === 1) return t('CHAT_DEPOSIT_14');
        if (chatItem.depositStatus === 2) return t('CHAT_DEPOSIT_15');
        if (chatItem.depositStatus === 3) return t('CHAT_DEPOSIT_16');
        if (chatItem.depositStatus === 5) return t('CHAT_DEPOSIT_19');
        if (chatItem.depositStatus === 4) {
            return t('CHAT_DEPOSIT_17');
        } else {
            return t('NO_INFORMATION');
        }
    };

    const getPaymentStatus = () => {
        if (chatItem.paymentStatus === 1) return t('CHAT_PAYMENT_1');
        if (chatItem.paymentStatus === 2) return t('CHAT_PAYMENT_2');
        if (chatItem.paymentStatus === 3) return t('CHAT_PAYMENT_3');
        if (chatItem.paymentStatus === 4) {
            return t('CHAT_PAYMENT_4');
        } else {
            return t('NO_INFORMATION');
        }
    };

    return (
        <div
            key={chatItem.booking_id}
            className={`border-b-gray-10 relative flex w-full cursor-pointer flex-col border-b py-2 pl-4 transition-colors ${
                bookingId === chatItem.booking_id
                    ? 'bg-blue-400 text-black'
                    : chatItem.status_color === 1
                    ? 'bg-green-100 hover:bg-white'
                    : chatItem.status_color === 2
                    ? 'bg-yellow-100 hover:bg-white'
                    : 'bg-gray-100 hover:bg-white'
            }`}
            onClick={changeHotel ? null : e => handlerGetMessages(e, hotelId, chatItem.booking_id)}
        >
            {/* room number  */}
            <div className="flex flex-row pr-8 text-sm font-semibold">
                {chatItem.date_to ? '' : t('CHAT_ROOM')} <span>{chatItem.room_number}</span>
                {/* contract status */}
                {chatItem.podpislon === 'НЕ ПОДПИСАН' && (
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" className="ml-2">
                        <path
                            d="M14.5 11.5L12 14M12 14L9.5 16.5M12 14L9.50002 11.5M12 14L14.5 16.5M13 3H5V21H19V9M13 3H14L19 8V9M13 3V7C13 8 14 9 15 9H19"
                            stroke="#9ca3af"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
            </div>
            <div className="mt-2 flex justify-between pr-2">
                <div className={`mr-2 ${chatItem.deposit !== undefined && chatItem.payment !== undefined ? 'w-1/3' : 'w-full'}`}>
                    {/* guest name */}
                    <div className="flex w-full items-center truncate text-xs">
                        <span className="block h-full whitespace-normal">{chatItem.name}</span>
                    </div>

                    {/* dates of stay */}
                    {chatItem.date_from && (
                        <div className="flex items-center text-[0.625rem]">
                            <span>{chatItem.date_from}-</span>

                            <span> {chatItem.date_to}</span>
                        </div>
                    )}

                    {/* door code */}
                    {/* {!chatItem.lockCode && (
                        <div className="flex flex-col items-start text-xs">
                            <span className="mr-1 font-bold">{t('CHAT_LOCK_CODE')}:</span>
                            <span>{chatItem.lockCode}#1123123123</span>
                        </div>
                    )} */}
                </div>

                {chatItem.deposit !== undefined && chatItem.payment !== undefined && (
                    <>
                        <div className="mr-2 w-1/3">
                            <div className="flex flex-col text-center">
                                <span className="text-xs">{t('CHAT_PAYMENT')}</span>
                                <span className="text-xs font-bold">{getPaymentStatus()}</span>
                            </div>
                        </div>
                        <div className="flex w-1/3 flex-col text-center">
                            <span className="text-xs">{t('CHAT_DEPOSIT_18')}</span>
                            <span className="text-xs font-bold">{getDepositStatus()}</span>
                        </div>
                    </>
                )}
            </div>

            {/* unread messages */}
            {chatItem.unreadCount > 0 && <div className="absolute left-1 top-3.5 h-2 w-2 rounded-full bg-red-600 p-0.5 text-center text-xs text-white"></div>}

            {/* context menu */}
            {chatItem.deposit !== undefined && chatItem.payment !== undefined && (
                <button
                    onClick={() => setIsOpenChatMenu(!isOpenChatMenu)}
                    ref={buttonRef}
                    type="button"
                    className={`isOpenChatMenu absolute right-2 top-1 ${
                        bookingId === chatItem.booking_id ? 'hover:text-black' : 'hover:text-blue-500'
                    } transition-colors`}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z"
                            className="fill-current"
                        />
                    </svg>
                </button>
            )}
            <ChatMenu
                isOpen={isOpenChatMenu}
                setIsOpen={setIsOpenChatMenu}
                buttonRef={buttonRef}
                phoneNumber={chatItem.phone}
                setIsOpenPopup={setIsOpenPopup}
                price={chatItem.price}
            />
            <DepositPopup
                isOpen={isOpenPopup}
                setIsOpen={setIsOpenPopup}
                bookingId={chatItem.booking_id}
                payment={chatItem.payment}
                deposit={chatItem.deposit}
                checkLink={chatItem.checkLink}
            />
        </div>
    );
};
